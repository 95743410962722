import React from "react";

const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    // Вычисляем диапазон страниц для отображения
    const pagesToShow = 4;

    // Вычисляем диапазон страниц для отображения
    let startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    let endPage = startPage + pagesToShow - 1;

    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - pagesToShow + 1);
    }

    return (
        <div className="pagination">
            {/* Кнопка "Первая страница" */}
            <button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(1)}
            >
                &laquo;
            </button>

            {/* Кнопка "Назад" */}
            <button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
            >
                &lsaquo;
            </button>

            {/* Номера страниц */}
            {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
                <button
                    key={startPage + i}
                    className={`page-btn ${currentPage === startPage + i ? "active" : ""}`}
                    onClick={() => handlePageChange(startPage + i)}
                >
                    {startPage + i}
                </button>
            ))}

            {/* Кнопка "Вперед" */}
            <button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
            >
                &rsaquo;
            </button>

            {/* Кнопка "Последняя страница" */}
            <button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(totalPages)}
            >
                &raquo;
            </button>
        </div>
    );
};

export default Pagination;
